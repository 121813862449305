import React, { useState } from "react";
import DreamList from "./components/DreamList";
import DreamInput from "./components/DreamInput";
import './styles.css';

const App = () => {
  const [dreams, setDreams] = useState([]);

  const addDream = (newDream) => {
    setDreams([...dreams, newDream]);
  };

  return (
    <div className="app-container">
      <header>
        <h1>Dreamers</h1>
        <p>Share your wildest dreams and be inspired by fellow dreamers.</p>
      </header>
      <DreamInput addDream={addDream} />
      <DreamList dreams={dreams} />
    </div>
  );
};

export default App;
