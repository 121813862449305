import React from "react";

const DreamList = ({ dreams }) => {
  return (
    <div className="dream-list">
      {dreams.length > 0 ? (
        dreams.map((dream, index) => (
          <div key={index} className="dream-item">
            <p>{dream}</p>
          </div>
        ))
      ) : (
        <p>No dreams yet. Be the first dreamer!</p>
      )}
    </div>
  );
};

export default DreamList;
