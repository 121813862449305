// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Use 'react-dom/client' for React 18
import './styles.css';
import App from './App';

// Get the root element from your HTML
const rootElement = document.getElementById('root');

// Create the root using React 18's createRoot method
const root = ReactDOM.createRoot(rootElement);

// Render your app into the root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

